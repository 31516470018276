import { Ionicons } from '@expo/vector-icons';
import { Box, ScrollView, Stack } from 'native-base';
import React, { useCallback, useState } from 'react';
import { Platform, RefreshControl } from 'react-native';
import { FullWidthBoxTitle } from '../../components/home/FullWidthBoxTitle';
import { GalleryList } from '../../components/home/GalleryList';
import { HomeFeedItemsList } from '../../components/home/HomeFeedItemsList';
import { CareerCenterList } from '../../components/home/CareerCenterList';
import { SponsorsList } from '../../components/home/SponsorsList';
import { UpcomingActivityList } from '../../components/home/UpcomingActivityList';
import { WelcomeMessage } from '../../components/home/WelcomeMessage';
import { Alert, Button, Card, PageWrapper, Section } from '../../components/ui';
import { FullWidthSection } from '../../components/ui/sections/FullWidthSection';
import { useAlerts, useDataLoader } from '../../hooks';
import { HomeStackScreenWithNavProps } from '../../navigation/AppNavigation';
import { useAppDispatch } from '../../store';
import { authActions } from '../../store/actions';

export const HomeScreen: React.FC<HomeStackScreenWithNavProps<'Home'>> = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const { load: reloadData } = useDataLoader();
  const { alerts } = useAlerts();

  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);

    await Promise.allSettled([reloadData(), dispatch(authActions.fetchUser())]);

    setRefreshing(false);
  }, [reloadData]);

  return (
    <>
      <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        <PageWrapper>
          <Stack flex={1} p="4">
            {alerts.length > 0 && (
              <Section mb="4" title={''} hideIcon>
                {alerts.map((a) => (
                  <Alert key={a.id} title={a.title} message={a.message} status={a.type} canClose={a.canClose} />
                ))}
              </Section>
            )}
            <Section
              title="Upcoming Activity"
              mb="8"
              renderIcon={(color) => <Ionicons name="calendar-outline" color={color} size={20} />}
            >
              <UpcomingActivityList onPressActivity={(activity) => navigation.navigate('Event', { id: activity.id })} />
              <Box mt={2}>
                <Button
                  text="See Full Schedule"
                  color="primary"
                  onPress={() =>
                    navigation.navigate(Platform.OS === 'web' ? 'Drawer' : 'Tabs', {
                      screen: 'ScheduleStack',
                      params: { screen: 'Schedule' },
                    })
                  }
                />
              </Box>
            </Section>
            <Section
              title="Feed"
              renderIcon={(color) => <Ionicons name="information-circle-outline" color={color} size={20} />}
            >
              <HomeFeedItemsList />
            </Section>
            <Section
              title="Career Center"
              renderIcon={(color) => <Ionicons name="briefcase-outline" color={color} size={20} />}
            >
              <CareerCenterList />
            </Section>
            <FullWidthSection>
              <FullWidthBoxTitle title="Gallery" />
              <GalleryList />
            </FullWidthSection>
            <FullWidthSection>
              <FullWidthBoxTitle title="Our Sponsors" />
              <SponsorsList />
              <Button text="See All Sponsors" color="primary" onPress={() => navigation.navigate('Sponsors')} />
            </FullWidthSection>
          </Stack>
        </PageWrapper>
      </ScrollView>
      <WelcomeMessage />
    </>
  );
};
