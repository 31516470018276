import { Contact } from '../models/data';
import BaseService from './BaseService';

export class ContactService extends BaseService {
  async getContacts() {
    return (await this.getJson<Contact[]>('/contacts')).data;
  }

  async updateCheckInStatus(contactId: number, contact: Contact) {
    return (await this.putJson<Contact>(`/contacts/${contactId}`, contact)).data;
  }
}

export default new ContactService();
