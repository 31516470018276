import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as Clipboard from 'expo-clipboard';
import moment, { Moment } from 'moment-timezone';
import { Divider, Link, Text, VStack } from 'native-base';
import { Animated } from 'react-native';
import { Button } from '../../components/ui';
import { RoleNames } from '../../constants';
import Curricula from '../../constants/Curricula';
import { Campaign, Contact, ProgramEvent, User } from '../../models/data';
import { Activity, Alert } from '../../models/view';
import { RootStackParamList } from '../../navigation';
import { colors } from '../../styles';
import { generateShortUUID, openUrl } from '../../utils';
import { nativeAlert } from '../../utils/ui';

export const BACKGROUND_CHECK_AND_TRAINING_ALERT_TITLE = 'Background Check & Training Required';
export const STUDENT_CHECKBOXES_DISMISSAL_KEY = 'STUDENT_CHECKBOXES_IS_DISMISSED';
export const TRAINING_BACKGROUND_DISMISSAL_KEY = 'TRAINING_BACKROUND_IS_DISMISSED';

export const shouldShowBackgroundCheckAlert = (contact: Contact) => {
  if (!contact.bgCheckDate) {
    return true;
  }

  const todayMoment: Moment = moment().tz('UTC').startOf('day');
  const julyFirstDayOfYear: number = todayMoment.isLeapYear() ? 183 : 182;

  let earliestValidYear: number;

  if (todayMoment.dayOfYear() < julyFirstDayOfYear) {
    // If today is before the start of the current year's fiscal year, set the earliest valid year to 3 years ago.
    earliestValidYear = todayMoment.subtract(3, 'years').year();
  } else {
    // If today is after the start of the current year's fiscal year, set the earliest valid year to 2 years ago.
    earliestValidYear = todayMoment.subtract(2, 'years').year();
  }

  // Earliest valid moment is July 1 (fiscal year beginning) of the earliest valid year
  const earliestValidMoment = moment(earliestValidYear + '-07-01', 'YYYY-MM-DD')
    .tz('UTC')
    .startOf('day');
  const bgCheckMoment = moment(contact.bgCheckDate);

  return bgCheckMoment.isBefore(earliestValidMoment);
};

export const shouldShowTrainingAlert = (contact: Contact) => {
  return !contact.trainingCompleted;
};

const isItAtLeast60MinutesAfterTheEventStartTime = (event?: ProgramEvent, timeZone?: string) => {
  if (!event) {
    return false;
  }

  const timezone = timeZone || 'UTC';
  const momentStartDateTime = moment(event.startDateTime).tz(timezone);
  const momentStartDateTimePlusSixtyMinutes = momentStartDateTime.clone().add(60, 'minutes');
  const momentNow = moment().tz(timezone);

  return momentStartDateTime.isSameOrBefore(momentNow) && momentStartDateTimePlusSixtyMinutes.isSameOrAfter(momentNow);
};

export const shouldShowPreAssessmentAlert = (campaign: Campaign, event?: ProgramEvent) => {
  return isItAtLeast60MinutesAfterTheEventStartTime(event, campaign.timezone);
};

export const shouldShowPostAssessmentAlert = (campaign: Campaign, event?: ProgramEvent) => {
  return isItAtLeast60MinutesAfterTheEventStartTime(event, campaign.timezone);
};

export const shouldShowProgramSurveyAlert = (campaign: Campaign) => {
  if (!campaign.fieldTripDate) {
    return false;
  }

  const timezone = campaign.timezone || 'UTC';
  const momentFieldTripDate = moment(campaign.fieldTripDate).tz(timezone);

  return momentFieldTripDate.isSame(new Date(), 'day');
};

export const shouldShowReenrollmentAlert = (currentUser: User, registrationSubmitted: boolean) => {
  return currentUser.eligibleForReenrollment && !registrationSubmitted;
};

export const shouldShowSpringEnrollmentAlert = (currentUser: User, campaign: Campaign | null) => {
  // Must be a student or volunteer
  if (!userIsStudent(currentUser) && !userIsVolunteer(currentUser)) {
    return false;
  }

  // Must not be mentor interested
  if (userIsMentorInterested(currentUser)) {
    return false;
  }

  if (campaign) {
    if (userIsStudent(currentUser)) {
      // Student campaigns must not be protege
      if (campaignIsProtege(campaign)) return false;
    } else if (userIsVolunteer(currentUser)) {
      // Volunteer campaigns must not be mentor
      if (campaignIsMentor(campaign)) return false;
    }

    // If campaign name is present, check if today is after October of the current campaign year
    if (campaign.name && !todayIsAfterOctoberOfCurrentCampaignYear(campaign)) {
      return false;
    }
  } else {
    // If campaign is not present, check if today is after April 1 of the current year
    if (!todayIsBeforeAprilOfCurrentYear(campaign)) {
      return false;
    }
  }

  return true;
};

export const shouldShowSpringEnrollmentConfirmationAlert = (currentUser: User, campaign: Campaign) => {
  // Must be a student
  if (!userIsStudent(currentUser)) {
    return false;
  }

  // Must be mentor interested
  if (!userIsMentorInterested(currentUser)) {
    return false;
  }

  // If campaign is Protege, check if today is after campaign second volunteer shift
  if (campaignIsProtege(campaign) && todayIsAfterTheCampaignSecondVolunteerShift(campaign)) {
    return false;
  }

  return true;
};

export const userIsStudent = (currentUser?: User | null) => {
  return currentUser?.role.name === RoleNames.STUDENT;
};

export const userIsVolunteer = (currentUser?: User | null) => {
  return currentUser?.role.name === RoleNames.VOLUNTEER;
};

const userIsMentorInterested = (currentUser: User) => {
  return currentUser.contact?.mentorInterested === 'Yes';
};

// NOTE: This is actually just checking to see that the current month is one of the following months:
// Nov, Dec, Jan, Feb, Mar, Apr, May
const todayIsAfterOctoberOfCurrentCampaignYear = (campaign: Campaign) => {
  const timezone = campaign.timezone || 'UTC';
  const momentToday = moment().tz(timezone);
  const acceptableMonths = [0, 1, 2, 3, 4, 10, 11];

  return acceptableMonths.includes(momentToday.month());
};

const todayIsBeforeAprilOfCurrentYear = (campaign: Campaign | null) => {
  const timezone = campaign && campaign.timezone ? campaign.timezone : 'UTC';
  const currentYear = moment().tz(timezone).year();
  const momentCurrentYearAprilFirst = moment(`${currentYear}-04-01`).tz(timezone);
  const momentToday = moment().tz(timezone);

  return momentCurrentYearAprilFirst.isAfter(momentToday);
};

// const todayIsAfterMayOfCurrentYear = (campaign: Campaign | null) => {
//   const timezone = campaign && campaign.timezone ? campaign.timezone : 'UTC';
//   const currentYear = moment().tz(timezone).year();
//   const momentCurrentYearJuneFirst = moment(`${currentYear}-06-01`).tz(timezone);
//   const momentToday = moment().tz(timezone);

//   return momentCurrentYearJuneFirst.isSameOrBefore(momentToday);
// };

const campaignIsProtege = (campaign: Campaign) => {
  return getCampaignNameElement(campaign, 'program') === 'Protege';
};

const campaignIsMentor = (campaign: Campaign) => {
  return getCampaignNameElement(campaign, 'program') === 'Mentors';
};

const getCampaignNameElement = (campaign: Campaign, element: string) => {
  const nameRegexPattern = /^(\w{4})\/(.+)\/(.+),.([SVPM]{1}\w+$)/i;
  const elements = campaign.name.match(nameRegexPattern);

  if (!elements) return '';

  switch (element) {
    case 'year':
      return elements[1] || '';
    case 'city':
      return elements[2] || '';
    case 'school':
      return elements[3] || '';
    case 'program':
      return elements[4] || '';
    default:
      break;
  }

  return '';
};

const todayIsAfterTheCampaignSecondVolunteerShift = (campaign: Campaign) => {
  //// • the current date is before the date of the current campaign's second volunteer shift date
  const timezone = campaign?.timezone || 'UTC';
  const momentToday = moment().tz(timezone);
  const unacceptableMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  return unacceptableMonths.includes(momentToday.month());
};

const renderBackgroundAlertContent = () => {
  return (
    <VStack>
      <Text>
        You need a background check to complete your registration and participate with RTSWS. In the next 48 hours you
        will receive an email inviting you to complete your background check through Verified First. Please check your
        spam box if you do not receive the email.
      </Text>
      <Text>This notification will disappear when your background check is returned and approved.</Text>
    </VStack>
  );
};

const renderStudentRegisteredAlertContent = (currentUser: User) => {
  const hideSection = !currentUser || (!currentUser.eligibleForReenrollment && currentUser.campaigns.length === 0);
  const userIsRegisteredForCampaign = currentUser.campaigns.length > 0;

  if (hideSection) {
    return <></>;
  }
  return (
    <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Ionicons
        name={userIsRegisteredForCampaign ? 'checkbox-outline' : 'square-outline'}
        color={colors.secondary}
        size={22}
      />
      <Text fontStyle="italic" ml={2}>
        Registered for School Program
      </Text>
    </VStack>
  );
};

const renderVolunteerRegisteredAlertContent = () => {
  return (
    <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Ionicons name={'checkbox-outline'} color={colors.secondary} size={22} />
      <Text fontStyle="italic" ml={2}>
        Registered
      </Text>
    </VStack>
  );
};

const renderTrainingAlertContent = (campaign: Campaign, getSettingValueByName: (name: string) => string) => {
  // Fall back to generic training url and password if no curriculum is set
  let url = 'https://rockthestreetwallstreet.com/curriculum-training';
  let password = 'Training';

  const curriculum = Curricula.find((c) => c.name === campaign.curriculumSet);
  if (curriculum) {
    url = getSettingValueByName(curriculum.curriculum_url_slug);
    password = getSettingValueByName(curriculum.curriculum_password_slug);
  }
  return (
    <VStack>
      <Text>Volunteer Training needs to be completed.</Text>
      <Button
        text="Tap to view training curriculum"
        color="secondary"
        onPress={async () => {
          await Clipboard.setStringAsync(password);
          await nativeAlert(
            'Volunteer Training',
            `The password has been copied to the clipboard. Press "Okay" to continue.`,
          );
          openUrl(url);
          setTimeout(() => {
            Clipboard.setStringAsync('');
          }, 30000);
        }}
      />
      <Text>
        Password: <Text fontFamily="mono">{password}</Text>
      </Text>
      <Text mt={2}>After completion, this notice will be removed within 3 days.</Text>
    </VStack>
  );
};

export const makeStudentCheckboxesAlert = (
  currentUser: User,
  handleDismissAlert: () => void,
  fadeAnim: Animated.Value,
): Alert => {
  const animatedDismiss = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      handleDismissAlert();
    });
  };

  return {
    id: generateShortUUID(),
    type: 'warning',
    title: 'Registration Checklist',
    message: () => {
      const userIsGraduwayRegistered = currentUser.graduwayId;
      return (
        <Animated.View style={{ opacity: fadeAnim }}>
          {renderStudentRegisteredAlertContent(currentUser)}

          <Divider my={2} />

          <VStack space={4}>
            <VStack>
              <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Ionicons
                  name={currentUser.graduwayId ? 'checkbox-outline' : 'square-outline'}
                  color={colors.secondary}
                  size={22}
                />

                <Text ml={2} fontStyle="italic">
                  Registered with Alumnae Career Center
                </Text>
              </VStack>
            </VStack>
            {!currentUser.graduwayId && (
              <VStack ml={8}>
                <Link href="http://alum.rockthestreetwallstreet.com/" w="100%" isExternal={true}>
                  Register here!
                </Link>
              </VStack>
            )}
          </VStack>

          {true && userIsGraduwayRegistered && (
            <VStack mt={2}>
              <VStack flex={1}>
                <Button text="Dismiss" color="secondary" onPress={() => animatedDismiss()} />
              </VStack>
            </VStack>
          )}
        </Animated.View>
      );
    },
    canClose: true,
  };
};

export const makeVolunteerCheckboxesAlert = (
  campaign: Campaign,
  userShouldSeeBackgroundAlert: boolean,
  userShouldSeeTrainingAlert: boolean,
  getSettingValueByName: (name: string) => string,
  handleDismissAlert: () => void,
  fadeAnim: Animated.Value,
): Alert => {
  const animatedDismiss = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      handleDismissAlert();
    });
  };

  return {
    id: generateShortUUID(),
    type: 'warning',
    title: BACKGROUND_CHECK_AND_TRAINING_ALERT_TITLE,
    message: () => (
      <Animated.View style={{ opacity: fadeAnim }}>
        {renderVolunteerRegisteredAlertContent()}

        <Divider my={2} />

        <VStack space={4}>
          <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Ionicons
              name={userShouldSeeBackgroundAlert ? 'square-outline' : 'checkbox-outline'}
              color={colors.secondary}
              size={22}
            />
            <Text fontStyle="italic" ml={2}>
              Background Check
            </Text>
          </VStack>

          {userShouldSeeBackgroundAlert ? renderBackgroundAlertContent() : <></>}
        </VStack>
        <Divider my={2} />
        <VStack space={4}>
          <VStack space={4}>
            <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Ionicons
                name={userShouldSeeTrainingAlert ? 'square-outline' : 'checkbox-outline'}
                color={colors.secondary}
                size={22}
              />
              <Text fontStyle="italic" ml={2}>
                Volunteer Training
              </Text>
            </VStack>
            {userShouldSeeTrainingAlert ? renderTrainingAlertContent(campaign, getSettingValueByName) : <></>}
          </VStack>
        </VStack>
        {!userShouldSeeBackgroundAlert && !userShouldSeeTrainingAlert && (
          <VStack>
            <VStack flex={2} my={1}>
              <Text>Great Job! You've completed the required background check and training.</Text>
            </VStack>
            <VStack flex={1}>
              <Button text="Dismiss" color="secondary" onPress={() => animatedDismiss()} />
            </VStack>
          </VStack>
        )}
      </Animated.View>
    ),
    canClose: false,
  };
};

export const makeCheckInAlert = (
  currentActivity: Activity,
  handleCheckIn: () => void,
  setCheckInClicked: (value: boolean) => void,
  checkInClicked: boolean,
): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Check-in',
  message: () => {
    return checkInClicked ? (
      <VStack space={4} display="flex" flexDirection="row" alignItems="center">
        <Text>Thank you for checking in!</Text>
        <Ionicons ml={2} name="checkmark-circle-outline" color={colors.primary} size={22} />
      </VStack>
    ) : (
      <VStack space={4}>
        <Text>Are you attending {currentActivity?.title}?</Text>
        <Text>Let us know by checking in!</Text>
        <Button
          text="Check-In"
          color="secondary"
          onPress={() => {
            setCheckInClicked(true);
            handleCheckIn();
          }}
        />
      </VStack>
    );
  },
  canClose: false,
});

export const makePreAssessmentAlert = (preAssessmentUrl: string): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Take the Pre-Assessment',
  message: () => <Button text="Open" color="secondary" onPress={() => openUrl(preAssessmentUrl)} />,
  canClose: false,
});

export const makePostAssessmentAlert = (postAssessmentUrl: string): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Take the Post-Assessment',
  message: () => <Button text="Open" color="secondary" onPress={() => openUrl(postAssessmentUrl)} />,
  canClose: false,
});

export const makeProgramSurveyAlert = (programSurveyUrl: string): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Take the Program Survey',
  message: () => <Button text="Open" color="secondary" onPress={() => openUrl(programSurveyUrl)} />,
  canClose: false,
});

export const makeReenrollmentAlert = (currentUser: User): Alert => ({
  id: generateShortUUID(),
  type: 'warning',
  title: 'Re-enroll',
  message: () => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const yearString = `${currentYear}/${nextYear}`;

    const isStudent = userIsStudent(currentUser);
    return (
      <Button
        text={`Enroll for school year ${yearString}`}
        color="primary"
        onPress={() => navigation.navigate(isStudent ? 'StudentReenrollment' : ('VolunteerReenrollment' as any))}
      />
    );
  },
  canClose: false,
});

export const makeSpringEnrollmentAlert = (currentUser: User): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Spring Enrollment',
  message: () => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    if (userIsStudent(currentUser)) {
      return (
        <Button
          text="Enroll For Spring Mentorship"
          color="primary"
          onPress={() => navigation.navigate('SpringEnrollment')}
        />
      );
    } else if (userIsVolunteer(currentUser)) {
      return (
        <Button text="Sign Up as a Mentor" color="primary" onPress={() => navigation.navigate('SpringEnrollment')} />
      );
    }
  },
  canClose: false,
});

export const makeSpringEnrollmentConfirmationAlert = (): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Spring Enrollment',
  message: () => {
    return (
      <VStack space={4}>
        <Text>You are enrolled for Spring Mentorship!</Text>
      </VStack>
    );
  },
  canClose: false,
});
