import * as SecureStore from 'expo-secure-store';
import { useCheckIn, useSettings } from '..';
import { useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import { Alert } from '../../models/view';
import { useAppSelector } from '../../store';

import {
  makeCheckInAlert,
  makePostAssessmentAlert,
  makePreAssessmentAlert,
  makeProgramSurveyAlert,
  makeReenrollmentAlert,
  makeSpringEnrollmentAlert,
  makeSpringEnrollmentConfirmationAlert,
  makeStudentCheckboxesAlert,
  makeVolunteerCheckboxesAlert,
  shouldShowBackgroundCheckAlert,
  shouldShowPostAssessmentAlert,
  shouldShowPreAssessmentAlert,
  shouldShowProgramSurveyAlert,
  shouldShowReenrollmentAlert,
  shouldShowSpringEnrollmentAlert,
  shouldShowSpringEnrollmentConfirmationAlert,
  shouldShowTrainingAlert,
  STUDENT_CHECKBOXES_DISMISSAL_KEY,
  TRAINING_BACKGROUND_DISMISSAL_KEY,
  userIsStudent,
  userIsVolunteer,
} from './utils';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

export const useAlerts = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const campaign = useAppSelector((state) => state.campaigns.currentCampaign);
  const programEvents = [...useAppSelector((state) => state.programEvents.programEvents)];
  const { canCheckIn, currentActivity, handleCheckIn } = useCheckIn();
  const { registrationSubmitted } = useAppSelector((state) => state.registrations);
  const { getSettingValueByName } = useSettings();
  const [checkInClicked, setCheckInClicked] = useState(false);
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [userHasDismissedVolunteerCheckboxesAlert, setUserHasDismissedVolunteerCheckboxesAlert] = useState(true);
  const [userHasDismissedStudentCheckboxesAlert, setUserHasDismissedStudentCheckboxesAlert] = useState(true);
  const firstEvent = programEvents.shift();
  const lastEvent = programEvents.pop();
  const fadeAnim = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    getStoredStudentCheckboxesDismissalStatus();
    getStoredVolunteerCheckboxesDismissalStatus();
  }, []);

  useEffect(() => {
    const newAlerts: Alert[] = [];

    if (!currentUser) {
      setAlerts(newAlerts);
      return;
    }

    if (userIsVolunteer(currentUser) && campaign && currentUser.contact) {
      const userShouldSeeBackgroundAlert = shouldShowBackgroundCheckAlert(currentUser.contact);
      const userShouldSeeTrainingAlert = shouldShowTrainingAlert(currentUser.contact);

      if (userShouldSeeBackgroundAlert || userShouldSeeTrainingAlert) {
        setUserHasDismissedVolunteerCheckboxesAlert(false);
        handleResetBackgroundAndTrainingAlertDismissalStatus();
      }
      if (!userHasDismissedVolunteerCheckboxesAlert) {
        newAlerts.push(
          makeVolunteerCheckboxesAlert(
            campaign,
            userShouldSeeBackgroundAlert,
            userShouldSeeTrainingAlert,
            getSettingValueByName,
            handleDismissVolunteerCheckboxesAlert,
            fadeAnim,
          ),
        );
      }
    }

    if (userIsStudent(currentUser)) {
      // student registration checklist
      const userIsInCampaign = currentUser && currentUser.campaigns && currentUser.campaigns.length > 0;
      const userCannotRegister = !currentUser.eligibleForReenrollment && !userIsInCampaign;

      if (!userCannotRegister) {
        if (!currentUser.graduwayId || !userIsInCampaign) {
          setUserHasDismissedStudentCheckboxesAlert(false);
          handleResetStudentCheckboxesDismissalStatus();
        }
        if (!userHasDismissedStudentCheckboxesAlert) {
          newAlerts.push(makeStudentCheckboxesAlert(currentUser, handleDismissStudentCheckboxesAlert, fadeAnim));
        }
      }

      // Student check-in
      if (campaign && canCheckIn && currentActivity) {
        newAlerts.push(makeCheckInAlert(currentActivity, handleCheckIn, setCheckInClicked, checkInClicked));
      }

      // Student pre-assessment
      if (campaign && shouldShowPreAssessmentAlert(campaign, firstEvent)) {
        newAlerts.push(makePreAssessmentAlert(campaign.preAssessmentLink));
      }

      // Student post-assessment
      if (campaign && shouldShowPostAssessmentAlert(campaign, lastEvent)) {
        newAlerts.push(makePostAssessmentAlert(campaign.postAssessmentLink));
      }
    }

    // End of campaign survey
    if (campaign && shouldShowProgramSurveyAlert(campaign)) {
      newAlerts.push(makeProgramSurveyAlert(campaign.endOfCourseSurvey));
    }

    // Reenrollment
    if (shouldShowReenrollmentAlert(currentUser, registrationSubmitted)) {
      newAlerts.push(makeReenrollmentAlert(currentUser));
    }

    // Spring enrollment
    if (shouldShowSpringEnrollmentAlert(currentUser, campaign)) {
      newAlerts.push(makeSpringEnrollmentAlert(currentUser));
    }

    // Spring enrollment confirmation
    if (campaign && shouldShowSpringEnrollmentConfirmationAlert(currentUser, campaign)) {
      newAlerts.push(makeSpringEnrollmentConfirmationAlert());
    }

    setAlerts(newAlerts);
  }, [
    checkInClicked,
    currentUser,
    campaign,
    registrationSubmitted,
    userHasDismissedStudentCheckboxesAlert,
    userHasDismissedVolunteerCheckboxesAlert,
  ]);

  const getStoredVolunteerCheckboxesDismissalStatus = async () => {
    if (Platform.OS === 'web') {
      const val = await AsyncStorage.getItem(TRAINING_BACKGROUND_DISMISSAL_KEY);
      setUserHasDismissedVolunteerCheckboxesAlert(Boolean(val));
    } else {
      const val = await SecureStore.getItemAsync(TRAINING_BACKGROUND_DISMISSAL_KEY);
      setUserHasDismissedVolunteerCheckboxesAlert(Boolean(val));
    }
  };

  const getStoredStudentCheckboxesDismissalStatus = async () => {
    if (Platform.OS === 'web') {
      const val = await AsyncStorage.getItem(STUDENT_CHECKBOXES_DISMISSAL_KEY);
      setUserHasDismissedStudentCheckboxesAlert(Boolean(val));
    } else {
      const val = await SecureStore.getItemAsync(STUDENT_CHECKBOXES_DISMISSAL_KEY);
      setUserHasDismissedStudentCheckboxesAlert(Boolean(val));
    }
  };

  const handleDismissStudentCheckboxesAlert = () => {
    if (Platform.OS === 'web') {
      AsyncStorage.setItem(STUDENT_CHECKBOXES_DISMISSAL_KEY, 'true');
    } else {
      SecureStore.setItemAsync(STUDENT_CHECKBOXES_DISMISSAL_KEY, 'true');
    }

    setUserHasDismissedStudentCheckboxesAlert(true);
  };

  const handleDismissVolunteerCheckboxesAlert = () => {
    if (Platform.OS === 'web') {
      AsyncStorage.setItem(TRAINING_BACKGROUND_DISMISSAL_KEY, 'true');
    } else {
      SecureStore.setItemAsync(TRAINING_BACKGROUND_DISMISSAL_KEY, 'true');
    }

    setUserHasDismissedVolunteerCheckboxesAlert(true);
  };

  const handleResetBackgroundAndTrainingAlertDismissalStatus = () => {
    if (Platform.OS === 'web') {
      AsyncStorage.removeItem(TRAINING_BACKGROUND_DISMISSAL_KEY);
    } else {
      SecureStore.deleteItemAsync(TRAINING_BACKGROUND_DISMISSAL_KEY);
    }
  };

  const handleResetStudentCheckboxesDismissalStatus = () => {
    if (Platform.OS === 'web') {
      AsyncStorage.removeItem(STUDENT_CHECKBOXES_DISMISSAL_KEY);
    } else {
      SecureStore.deleteItemAsync(STUDENT_CHECKBOXES_DISMISSAL_KEY);
    }
  };

  return { alerts };
};
