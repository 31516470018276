import { useCallback, useMemo } from 'react';
import { useAppSelector } from '../../store';
import { useScheduleItems } from './useScheduleItems';
import moment from 'moment-timezone';
import { Contact } from '../../models/data';
import { Activity } from '../../models/view';
import { contactService } from '../../services';

type Semester = 'Spring' | 'Fall';

export const useCheckIn = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { scheduleItems } = useScheduleItems();

  const currentActivity: Activity | undefined = useMemo(() => {
    const now = moment();

    const currentActivities = scheduleItems.filter((i) => {
      const eventStartTime = moment(i.dateTime);
      const eventEndTime = moment(eventStartTime, 'HH:mm:ss').add(i.duration, 'hours');
      return now.isSameOrAfter(eventStartTime) && now.isSameOrBefore(eventEndTime);
    });

    return currentActivities[0];
  }, [scheduleItems]);

  const currentSemester: Semester = useMemo(() => {
    const now = moment();
    const currentMonth = now.get('month') + 1;
    const springMonths = [1, 2, 3, 4, 5, 6];
    return springMonths.includes(currentMonth) ? 'Spring' : 'Fall';
  }, []);

  const currentSessionNumber = useMemo(() => {
    return scheduleItems.findIndex((i) => i.id === currentActivity?.id) + 1;
  }, [scheduleItems, currentActivity]);

  const sessionNumberAccessor = useMemo(() => {
    return `${currentSemester.toLowerCase()}Session${currentSessionNumber}` as keyof Contact;
  }, [currentSemester, currentSessionNumber]);

  const canCheckIn = useMemo(() => {
    if (currentActivity && currentUser && currentUser.contact) {
      const currentUserSessionCheckInValue = currentUser.contact[sessionNumberAccessor];

      if (!currentUserSessionCheckInValue) {
        return true;
      }
    }

    return false;
  }, []);

  const handleCheckIn = useCallback(async () => {
    if (currentUser && currentUser.contact && currentUser.contact.id) {
      const updatedContact = { ...currentUser.contact, [sessionNumberAccessor]: true };
      await contactService.updateCheckInStatus(currentUser?.contact.id, updatedContact);
    }
  }, [sessionNumberAccessor, currentUser]);

  return { canCheckIn, currentActivity, handleCheckIn };
};
